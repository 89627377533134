<template>
  <div>
    <div class="banner">
      <main-top title="在线指数还原" desc=""></main-top>
      <bordure-row title="指数还原" style="margin-bottom:30px;">
        <div class="tool-line">
          <el-tooltip content="导入的excel包含以下表格的标题即可" placement="top">
            <div class="import-btn">
              <i class="el-icon-upload"></i>
              &nbsp;
              <span>导入数据</span>
              <input class="excel-file"  ref="excelFile" type="file" accept=".xls,.xlsx"  @change="importData" />
            </div>
            
          </el-tooltip>
          <el-button icon="el-icon-upload" type="danger" size="small" @click="clearAll()">清除数据</el-button>
        </div>
        <el-table :data="formList"  max-height="600px" v-loading="importLoading">
            <el-table-column type="index"  label="序号" ></el-table-column>
            <el-table-column prop="jyValue" label="交易指数" align="center" >
              <template v-slot="{row}">
                <el-input v-model="row.jyValue" type="number" ></el-input>
              </template>
            </el-table-column>

            <el-table-column prop="llValue" label="流量指数" align="center" >
              <template v-slot="{row}">
                <el-input v-model="row.llValue" type="number" ></el-input>
              </template>
            </el-table-column>



            <el-table-column prop="kqValue" label="客群指数" align="center" >
              <template v-slot="{row}">
                <el-input v-model="row.kqValue" type="number" ></el-input>
              </template>
            </el-table-column>


            <el-table-column prop="scValue" label="收藏人气" align="center" >
              <template v-slot="{row}">
                <el-input v-model="row.scValue" type="number" ></el-input>
              </template>
            </el-table-column>


            <el-table-column prop="jgValue" label="加购人气" align="center" >
              <template v-slot="{row}">
                <el-input v-model="row.jgValue" type="number" ></el-input>
              </template>
            </el-table-column>


            <el-table-column prop="ssValue" label="搜索人气" align="center" >
              <template v-slot="{row}">
                <el-input v-model="row.ssValue" type="number" ></el-input>
              </template>
            </el-table-column>

            <el-table-column prop="zfzhValue" label="支付转化指数" align="center" >
              <template v-slot="{row}">
                <el-input v-model="row.zfzhValue" type="number" ></el-input>
              </template>
            </el-table-column>

            
            <el-table-column label="操作">
              <template v-slot="{row,$index}">
                  <el-button size="mini" type="danger"  @click="deleteRow($index)" >删除</el-button>
              </template>
            </el-table-column>



            

        </el-table>

        <div class="bototm-op">
          <el-button icon="el-icon-circle-plus-outline"  size="small"  @click="addRow()" > 添加</el-button>
        </div>
        <div class="bottom-tips">
          共有{{formList.length}}条数据
        </div>


        <div style="text-align: center;margin:20px;">
          <el-button type="primary" size="small" @click="transitionFn" style="width:140px;"  :disabled="!CanYouClick" >一键转化</el-button>
        </div>
        
        
        <div class="tool-line">
          <el-button icon="el-icon-download" size="small" type="primary" @click="export2Excel()">导出数据</el-button>
        </div>

        <el-table :data="transitionList"  max-height="600px" v-loading="tableLoading">
            <el-table-column type="index" label="序号" ></el-table-column>

            <el-table-column prop="jyConvertValue" label="支付金额" align="center" >
            </el-table-column>

            <el-table-column prop="llConvertValue" label="访客人数" align="center" >
            </el-table-column>

            <el-table-column prop="kqConvertValue" label="支付买家数" align="center" >
            </el-table-column>


            <el-table-column prop="scConvertValue" label="收藏人数" align="center" >
            </el-table-column>


            <el-table-column prop="jgConvertValue" label="加购人数" align="center" >
            </el-table-column>


            <el-table-column prop="ssConvertValue" label="搜索人数" align="center" >
            </el-table-column>

            <el-table-column prop="zfzhConvertValue" label="支付转化率" align="center" >
            </el-table-column>

            
            <el-table-column label="操作">
              <template>
                &nbsp;
              </template>
            </el-table-column>

            

        </el-table>



      </bordure-row>

      <!-- 讲解文案区域 -->
      <Introduce :introduce="introduce"></Introduce>
    </div>
  </div>
</template>
<script>
  import _ from "lodash";
  import moment from "moment";
  import {
    indexConversion
  } from "../../request/api"; //这里是引入请求
  import Introduce from "../public/Introduce";
  export default {
    data() {
      return {
        importLoading: false,
        tableLoading: false,
        formList: [
          {
            jyValue: "",
            llValue: "",
            kqValue: "",
            scValue: "",
            jgValue: "",
            ssValue: "",
            zfzhValue: "",
          }
        ],
        formFieldMapping:{
          "交易指数": "jyValue",
          "流量指数": "llValue",
          "客群指数": "kqValue",
          "收藏人气": "scValue",
          "加购人气": "jgValue",
          "搜索人气": "ssValue",
          "支付转化指数": "zfzhValue",
        },
        transitionList: [
          {
            jyConvertValue: "0",
            llConvertValue: "0",
            kqConvertValue: "0",
            scConvertValue: "0",
            jgConvertValue: "0",
            ssConvertValue: "0",
            zfzhConvertValue: "0",
          }
        ],
        CanYouClick: true,
        introduce: [{
          title: "功能介绍",
          icon: "&#xe60a;",
          content: [
            "免费在线生意参谋转化指数准确率较高",
          ],
        }, ], //介绍相关数组
      };
    },
    components: {
      Introduce,
    },
    methods: {
      importData(){
        try{
          
          let excelFile = this.$refs.excelFile;
          let files = excelFile.files;

          if(files == null || files.length == 0){
            throw new Error("未选择导入的文件");
          }

          let file = files[0];
          require.ensure([], async () => {
            try{
              this.importLoading = true;
              const { readToJson } = require("@/excel/export2Excel");
              let res = await readToJson(file);
              console.log("读取excel数据：" , res);
              this.formList = [];
              for(let i = 0; i < res.length; i++){
                let item = res[i];
                let chObj = {};
                for(let key in item){
                  let fieldName = this.formFieldMapping[key];
                  if(fieldName){
                    chObj[fieldName] = item[key];
                  }
                }
                //添加
                this.formList.push(chObj);
              }

              this.$message.success("导入成功");
              
              
            }catch(err){
              let msg = err.message || "未知错误";
              console.error("读取失败", err );
              this.$message.error("读取失败:" + msg);
            }finally{
              this.importLoading = false;
              excelFile.value = "";

            }
            
          });

        }catch(err){
          let msg = err.message || "未知错误";
          console.error("导入失败", err );
          this.$message.error("导入失败:" + msg);
        }
        
      },
      clearAll(){
        this.formList = [];
      },
      deleteRow(index){
        this.formList.splice(index, 1);
      },
      addRow(){
        this.formList.push(
          {
            jyValue: "",
            llValue: "",
            kqValue: "",
            scValue: "",
            jgValue: "",
            ssValue: "",
            zfzhValue: "",
          }
        );

      },
      export2Excel() {
        var that = this;
        require.ensure([], () => {
          const { export_json_to_excel } = require("@/excel/export2Excel"); // 这里必须使用绝对路径，使用@/+存放export2Excel的路径
          // 导出的表头名信息
          const tHeader = [
            "支付金额",
            "访客人数",
            "支付买家数",
            "收藏人数",
            "加购人数",
            "搜索人数",
            "支付转换率",
          
          ];
          // 导出的表头字段名，需要导出表格字段名
          const filterVal = [
            "jyConvertValue",
            "llConvertValue",
            "kqConvertValue",
            "scConvertValue",
            "jgConvertValue",
            "ssConvertValue",
            "zfzhConvertValue",
          ];

          const list = that.transitionList;
          let data = list.map(function (item) {
            return filterVal.map(function (path) {
              let newVal = _.get(item, path);
              return newVal;
            });
          });
          let curDate = moment().format("YYYY-MM-DD");
          export_json_to_excel(tHeader, data, "指数转换-" + curDate); // 导出的表格名称，根据需要自己命名
        });
      },
      transitionFn() {
        this.CanYouClick = false;
        this.transitionList = [];
        this.tableLoading = true;
        indexConversion({list: JSON.stringify(this.formList) }).then((data) => {
          this.transitionList = data.data || [];
        }).finally(() => {
          this.CanYouClick = true;
          this.tableLoading = false;
        });
      },
    },
  };
</script>

<style lang="less" scoped>
  .border-container {
      
      margin:  0 auto;
    .borderTitle {
      background-color: #f8f8f8;
      height: 48px;
      line-height: 48px;
      display: flex;
      font-size: 12px;
      color: #666666;
      border: 1px solid #e5e5e5;
      box-sizing: border-box;
    }

    .borderContent {
      padding: 15px 10px;
      box-sizing: border-box;
      border: 1px solid #e5e5e5;
      border-top: 0;
      text-align: center;
      /deep/.el-input__inner,
      /deep/.el-input {
        height: 36px;
        flex:1;
      }
    }

    /deep/.el-form-item {
      margin-bottom: 0;
    }
  }

  /deep/.el-form-item__label{
    line-height: 36px;
  }

  .flex {
    display: flex;
    justify-content: space-between;

    h3 {
      width: 300px;
      text-align: center;
    }
  }

  .transformation {
    width: 100px;
    height: 36px;
    line-height: 36px;
    background-color: #5d7cff;
    font-size: 12px;
    color: #ffffff;
    text-align: center;
    border-radius: 4px;
    margin: 16px auto 0;
    cursor: pointer;
  }

  .format {
    width: 200px;
    box-sizing: border-box;
    height: 36px;
    border: 1px solid #dddddd;
    border-radius: 4px;
    line-height: 36px;
    padding-left: 10px;
    flex:1;
  }

  .format-wrap{
     display: flex;
     flex-wrap: wrap;
     line-height: 36px;
  }

  .format-arrow{
    width: 160px; 
    text-align: center; 
    height: 36px;
    flex: 1;
  }

  .format-right-title{
    width: 100px;
  }


  .tool-line{
    padding: 20px;
    float:right;
  }

  .bototm-op{
    padding:6px;
    text-align: center;
  }

  .bottom-tips{
    padding:6px;
    background-color:#efefef;
    margin-bottom: 20px;
    font-size:12px; 
    color:#333;
  }

.import-btn{
  display: inline-block;
  margin-right: 10px;
  position: relative; 
  border: solid 1px #efefef;
  border-radius: 2px;
  padding: 9px 15px;
  background-color: #fff;
  font-size: 12px;
  cursor: pointer;

  &:hover{
    background-color: #f5f5f5;
  }

}

.excel-file{
  position:absolute;
  opacity: 0;
  top:0;
  bottom:0;
  left:0;
  right:0;
  background: red;
  z-index: 9999;
  cursor: pointer;
}
</style>