var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"banner"},[_c('main-top',{attrs:{"title":"在线指数还原","desc":""}}),_c('bordure-row',{staticStyle:{"margin-bottom":"30px"},attrs:{"title":"指数还原"}},[_c('div',{staticClass:"tool-line"},[_c('el-tooltip',{attrs:{"content":"导入的excel包含以下表格的标题即可","placement":"top"}},[_c('div',{staticClass:"import-btn"},[_c('i',{staticClass:"el-icon-upload"}),_vm._v(" "),_c('span',[_vm._v("导入数据")]),_c('input',{ref:"excelFile",staticClass:"excel-file",attrs:{"type":"file","accept":".xls,.xlsx"},on:{"change":_vm.importData}})])]),_c('el-button',{attrs:{"icon":"el-icon-upload","type":"danger","size":"small"},on:{"click":function($event){return _vm.clearAll()}}},[_vm._v("清除数据")])],1),_c('el-table',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.importLoading),expression:"importLoading"}],attrs:{"data":_vm.formList,"max-height":"600px"}},[_c('el-table-column',{attrs:{"type":"index","label":"序号"}}),_c('el-table-column',{attrs:{"prop":"jyValue","label":"交易指数","align":"center"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('el-input',{attrs:{"type":"number"},model:{value:(row.jyValue),callback:function ($$v) {_vm.$set(row, "jyValue", $$v)},expression:"row.jyValue"}})]}}])}),_c('el-table-column',{attrs:{"prop":"llValue","label":"流量指数","align":"center"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('el-input',{attrs:{"type":"number"},model:{value:(row.llValue),callback:function ($$v) {_vm.$set(row, "llValue", $$v)},expression:"row.llValue"}})]}}])}),_c('el-table-column',{attrs:{"prop":"kqValue","label":"客群指数","align":"center"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('el-input',{attrs:{"type":"number"},model:{value:(row.kqValue),callback:function ($$v) {_vm.$set(row, "kqValue", $$v)},expression:"row.kqValue"}})]}}])}),_c('el-table-column',{attrs:{"prop":"scValue","label":"收藏人气","align":"center"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('el-input',{attrs:{"type":"number"},model:{value:(row.scValue),callback:function ($$v) {_vm.$set(row, "scValue", $$v)},expression:"row.scValue"}})]}}])}),_c('el-table-column',{attrs:{"prop":"jgValue","label":"加购人气","align":"center"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('el-input',{attrs:{"type":"number"},model:{value:(row.jgValue),callback:function ($$v) {_vm.$set(row, "jgValue", $$v)},expression:"row.jgValue"}})]}}])}),_c('el-table-column',{attrs:{"prop":"ssValue","label":"搜索人气","align":"center"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('el-input',{attrs:{"type":"number"},model:{value:(row.ssValue),callback:function ($$v) {_vm.$set(row, "ssValue", $$v)},expression:"row.ssValue"}})]}}])}),_c('el-table-column',{attrs:{"prop":"zfzhValue","label":"支付转化指数","align":"center"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('el-input',{attrs:{"type":"number"},model:{value:(row.zfzhValue),callback:function ($$v) {_vm.$set(row, "zfzhValue", $$v)},expression:"row.zfzhValue"}})]}}])}),_c('el-table-column',{attrs:{"label":"操作"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
var $index = ref.$index;
return [_c('el-button',{attrs:{"size":"mini","type":"danger"},on:{"click":function($event){return _vm.deleteRow($index)}}},[_vm._v("删除")])]}}])})],1),_c('div',{staticClass:"bototm-op"},[_c('el-button',{attrs:{"icon":"el-icon-circle-plus-outline","size":"small"},on:{"click":function($event){return _vm.addRow()}}},[_vm._v(" 添加")])],1),_c('div',{staticClass:"bottom-tips"},[_vm._v(" 共有"+_vm._s(_vm.formList.length)+"条数据 ")]),_c('div',{staticStyle:{"text-align":"center","margin":"20px"}},[_c('el-button',{staticStyle:{"width":"140px"},attrs:{"type":"primary","size":"small","disabled":!_vm.CanYouClick},on:{"click":_vm.transitionFn}},[_vm._v("一键转化")])],1),_c('div',{staticClass:"tool-line"},[_c('el-button',{attrs:{"icon":"el-icon-download","size":"small","type":"primary"},on:{"click":function($event){return _vm.export2Excel()}}},[_vm._v("导出数据")])],1),_c('el-table',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.tableLoading),expression:"tableLoading"}],attrs:{"data":_vm.transitionList,"max-height":"600px"}},[_c('el-table-column',{attrs:{"type":"index","label":"序号"}}),_c('el-table-column',{attrs:{"prop":"jyConvertValue","label":"支付金额","align":"center"}}),_c('el-table-column',{attrs:{"prop":"llConvertValue","label":"访客人数","align":"center"}}),_c('el-table-column',{attrs:{"prop":"kqConvertValue","label":"支付买家数","align":"center"}}),_c('el-table-column',{attrs:{"prop":"scConvertValue","label":"收藏人数","align":"center"}}),_c('el-table-column',{attrs:{"prop":"jgConvertValue","label":"加购人数","align":"center"}}),_c('el-table-column',{attrs:{"prop":"ssConvertValue","label":"搜索人数","align":"center"}}),_c('el-table-column',{attrs:{"prop":"zfzhConvertValue","label":"支付转化率","align":"center"}}),_c('el-table-column',{attrs:{"label":"操作"}},[void 0],2)],1)],1),_c('Introduce',{attrs:{"introduce":_vm.introduce}})],1)])}
var staticRenderFns = []

export { render, staticRenderFns }